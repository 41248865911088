import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import { getImage, StaticImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

import { CUSTOM_SOLUTIONS } from '../constants/routes';

const Splash = () => {
  const { placeholderImageDesktop } = useStaticQuery(graphql`
    query {
      placeholderImageDesktop: file(relativePath: { eq: "bg-hero-desktop.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1200
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);

  // Desktop splash image
  const imageDesktop = getImage(placeholderImageDesktop);
  const bgImageDesktop = convertToBgImage(imageDesktop);

  return (
    <>
      {/* Desktop splash */}
      <div className="hidden md:block">
        <BackgroundImage
          Tag="section"
          style={{
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "0",
            paddingTop: "31.63%",
          }}
          {...bgImageDesktop}
          preserveStackingContext
        >
          <div
            style={{
              position: "absolute",
              top: "19%",
              left: "13%",
            }}
          >
            <h1 className="text-[#3C0F76] text-2xl lg:text-4xl xl:text-5xl 2xl:text-6xl tracking-wide font-bold leading-normal lg:leading-relaxed xl:leading-relaxed 2xl:leading-relaxed font-serif">
              Bringing Ideas<br />to Life
            </h1>

            <p className="mt-4 lg:mt-8 xl:mt-12">
              <Link
                to={CUSTOM_SOLUTIONS}
                className="bg-[#FB3640] border-none rounded-full text-xs lg:text-base px-4 py-1 lg:px-12 lg:py-2 lg:font-semibold text-white hover:bg-[#E13039] hover:text-white"
              >
                Learn How
              </Link>
            </p>
          </div>
        </BackgroundImage>

        <div className="bg-[#3C0F76] text-white py-24 px-8 md:px-16 lg:px-48 2xl:px-64 md:grid md:grid-flow-col md:gap-x-8 lg:gap-x-16">
          <div className="md:col-span-1">
            <h2 className="text-4xl xl:text-5xl font-serif text-right">
              Success is not a blueprint, it requires custom solutions
            </h2>
          </div>
          <div className="md:col-span-1">
            <p className="font-light text-lg leading-8">
              At Hillside Lab, we are the masters of making your life easier by providing radically custom solutions to help your business operation efficiently, become more profitable, and provide the best experience for your customers.
            </p>
          </div>
        </div>
      </div>

      {/* Mobile splash */}
      <div className="md:hidden">
        <div className="bg-[#F0FDFC]">
          <div className="px-8 pt-8 pb-4">
            <h1 className="text-[#3C0F76] text-3xl tracking-wide font-bold leading-normal font-serif">
              Bringing Ideas<br />to Life
            </h1>

            <p className="mt-4 lg:mt-8 xl:mt-12">
              <Link
                to={CUSTOM_SOLUTIONS}
                className="bg-[#FB3640] border-none rounded-full text-xs text-base px-8 py-2 lg:px-12 lg:py-2 lg:font-semibold text-white hover:bg-[#E13039] hover:text-white"
              >
                Learn How
              </Link>
            </p>
          </div>
          <StaticImage
            className=""
            src="../images/bg-hero-mobile.png"
            alt="Upgrade your manual processes"
          />
        </div>

        <div className="bg-[#3C0F76] text-white py-24 px-8">
          <div className="">
            <h2 className="text-3xl font-serif leading-10">
              Success is not a blueprint, it requires custom solutions
            </h2>
          </div>
          <div className="mt-12">
            <p className="font-light leading-7">
              At Hillside Lab, we are the masters of making your life easier by providing radically custom solutions to help your business operation efficiently, become more profitable, and provide the best experience for your customers.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Splash;
