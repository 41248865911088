import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const NoProblem = () => {
  return (
    <div className="bg-[#FFF8F2] py-12">
      <h2 className="text-3xl md:text-4xl tracking-wide text-center px-8 font-serif">
        No problem we can't solve
      </h2>

      <p className="text-center px-12 font-light text-lg mt-12 md:px-48 lg:px-80">
        We love building data driven applications with custom logic. Our experienced team of engineers have built both software and hardware solutions for a number of industries.
      </p>

      <div className="px-8 lg:hidden">
        <div className="mt-8 flex">
          <div className="flex-1 text-center">
            <StaticImage
              src="../images/residential-real-estate.png"
              alt="Residential and commercial real estate"
            />
            <p className="text-sm font-light">
              Residential &<br />Commercial Real Estate
            </p>
          </div>
          <div className="flex-1 text-center">
            <StaticImage
              src="../images/healthcare.png"
              alt="Healthcare and bioinformatics"
            />
            <p className="text-sm font-light">
              Healthcare<br />& Bioinformatics
            </p>
          </div>
        </div>

        <div className="mt-8 flex">
          <div className="flex-1 text-center">
            <StaticImage
              src="../images/logistics-ecommerce.png"
              alt="Logistics and e-commerce"
            />
            <p className="text-sm font-light">
              Logistics &<br />eCommerce
            </p>
          </div>
          <div className="flex-1 text-center">
            <StaticImage
              src="../images/business-admin.png"
              alt="Business administration and productivity"
            />
            <p className="text-sm font-light">
              Business<br />Administration &<br />Productivity
            </p>
          </div>
        </div>

        <div className="mt-8 flex">
          <div className="flex-1 text-center">
            <StaticImage
              src="../images/education.png"
              alt="Education and online learning"
            />
            <p className="text-sm font-light">
              Education & Online<br />Learning
            </p>
          </div>
          <div className="flex-1 text-center">
            <StaticImage
              src="../images/banking-finance.png"
              alt="Banking and finance"
            />
            <p className="text-sm font-light">
              Banking<br />& Finance
            </p>
          </div>
        </div>
      </div>

      <div className="hidden mt-16 lg:flex justify-around px-24 xl:px-36">
        <div className="text-center">
          <StaticImage
            src="../images/residential-real-estate.png"
            alt="Residential and commercial real estate"
          />
          <p className="text-base font-light">
            Residential &<br />Commercial Real Estate
          </p>
        </div>

        <div className="text-center">
          <StaticImage
            src="../images/healthcare.png"
            alt="Healthcare and bioinformatics"
          />
          <p className="text-base font-light">
            Healthcare<br />& Bioinformatics
          </p>
        </div>

        <div className="text-center">
          <StaticImage
            src="../images/logistics-ecommerce.png"
            alt="Logistics and e-commerce"
          />
          <p className="text-base font-light">
            Logistics &<br />eCommerce
          </p>
        </div>

        <div className="text-center">
          <StaticImage
            src="../images/business-admin.png"
            alt="Business administration and productivity"
          />
          <p className="text-base font-light">
            Business<br />Administration &<br />Productivity
          </p>
        </div>

        <div className="text-center">
          <StaticImage
            src="../images/education.png"
            alt="Education and online learning"
          />
          <p className="text-base font-light">
            Education & Online<br />Learning
          </p>
        </div>

        <div className="text-center">
          <StaticImage
            src="../images/banking-finance.png"
            alt="Banking and finance"
          />
          <p className="text-base font-light">
            Banking<br />& Finance
          </p>
        </div>
      </div>
    </div>
  );
};

export default NoProblem;
