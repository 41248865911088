import React from 'react';
import { Link } from 'gatsby';

import { CONTACT_US } from '../constants/routes';

const HowCanWeHelp = () => {
  return (
    <div className="bg-[#3C0F76] py-12">
      <h2 className="text-4xl font-extralight tracking-wide text-white px-8 text-center font-serif">
        How can we help you?
      </h2>

      <p className="font-light text-lg text-white px-8 mt-8 text-center">
        Tell us about what you're looking for, and we'll be in contact to create a personalized experience.
      </p>

      <div className="mt-8 px-8 md:flex md:justify-around md:gap-x-6 lg:gap-x-16 lg:px-16">
        <Card
          title="Crawling"
          body="We have big ideas for our future, but we are just kicking off and we don't have a website."
        />

        <Card
          title="Walking"
          body="We're operating and have a website, but we're looking for more efficiencies and custom platforms."
        />

        <Card
          title="Running"
          body="We have been doing this for a while, but are looking for a revamp or more customized solutions."
        />
      </div>
    </div>
  );
};

const Card = ({ title, body }) => {
  return (
    <div className="mx-auto my-8 p-6 max-w-sm bg-[#FFF8F2] shadow-2xl shadow-[#5B23A4] rounded-lg flex items-center">
      <div>
        <h4 className="text-[#FB3640] font-bold text-xl">
          {title}
        </h4>

        <p className="mt-4 text-base font-light">
          "{body}"
        </p>
      </div>

      <div className="pl-6">
        <Link
          className="text-4xl text-[#FB3640] font-extralight"
          to={CONTACT_US}
        >
          >
        </Link>
      </div>
    </div>
  );
};

export default HowCanWeHelp;
