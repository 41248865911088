import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import * as ROUTES from '../constants/routes';

const HomepageMain = () => {

  return (
    <div>
      <div className="my-16 px-8 md:px-16 lg:px-48 2xl:px-64 md:flex md:gap-x-8 lg:gap-x-32">
        <div className="md:flex-1">
          <StaticImage
            className=""
            src="../images/upgrade-manual-processes.png"
            alt="Upgrade your manual processes"
          />
        </div>
        <div className="mt-6 md:flex-1">
          <h2 className="font-serif text-xl lg:text-3xl tracking-wide font-extralight">
            Looking to upgrade your manual processes?
          </h2>
          <p className="mt-4 font-light text-lg leading-7">
            Embrace automation. We integrate key business applications to improve the delivery of information and create seamless interaction, all while eliminating paperwork and process delays.
            <br />
            <br />
            <Link
              className="underline text-blue-700 font-extralight"
              to={ROUTES.CUSTOM_SOLUTIONS}
            >
              Learn More
            </Link>
          </p>
        </div>
      </div>

      <div className="my-16 px-8 md:px-16 lg:px-48 2xl:px-64 md:flex md:gap-x-8 lg:gap-x-32">
        <div className="md:flex-1">
          <StaticImage
            className=""
            src="../images/competitive-edge.png"
            alt="Find your competitive edge"
          />
        </div>
        <div className="mt-6 md:flex-1">
          <h2 className="font-serif text-xl lg:text-3xl tracking-wide font-extralight">
            Looking for the right competitive edge?
          </h2>
          <p className="mt-4 font-light text-lg leading-7">
            We can develop custom software, web, and mobile applications; or assist with cloud migration to give your company the right competitive edge.
            <br />
            <br />
            <Link
              className="underline text-blue-700"
              to={ROUTES.CUSTOM_SOLUTIONS}
            >
              Learn More
            </Link>
          </p>
        </div>
      </div>

      <div className="my-16 px-8 md:px-16 lg:px-48 2xl:px-64 md:flex md:gap-x-8 lg:gap-x-32">
        <div className="md:flex-1">
          <StaticImage
            className=""
            src="../images/customer-experience.png"
            alt="A better customer experience"
          />
        </div>
        <div className="mt-6 md:flex-1">
          <h2 className="font-serif text-xl lg:text-3xl tracking-wide font-extralight">
            Looking for a better customer experience?
          </h2>
          <p className="mt-4 font-light text-lg leading-7">
            As full stack developers, we will transform the way you do business by building front and back-end systems to provide a modern web-based customer experience.
            <br />
            <br />
            <Link
              className="underline text-blue-700"
              to={ROUTES.CUSTOM_SOLUTIONS}
            >
              Learn More
            </Link>
          </p>
        </div>
      </div>

      <div className="my-16 mb-24 lg:mb-48 px-8 md:px-16 lg:px-48 2xl:px-64 md:flex md:gap-x-8 lg:gap-x-32">
        <div className="md:flex-1">
          <StaticImage
            className=""
            src="../images/launch-new-products.png"
            alt="New product launch"
          />
        </div>
        <div className="mt-6 md:flex-1">
          <h2 className="font-serif text-xl lg:text-3xl tracking-wide font-extralight">
            Looking to launch a new product or service?
          </h2>
          <p className="mt-4 font-light text-lg leading-7">
            We can make it happen within weeks, not months! Providing you a high quality product or service in a timely manner is important to us.
            <br />
            <br />
            <Link
              className="underline text-blue-700"
              to={ROUTES.CUSTOM_SOLUTIONS}
            >
              Learn More
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default HomepageMain;
