import React from 'react';

import Layout from '../components/Layout';
import Splash from '../components/Splash';
import HomepageMain from '../components/HomepageMain';
import NoProblem from '../components/NoProblem';
import HowCanWeHelp from '../components/HowCanWeHelp';
import SEO from '../components/SEO';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <main>
        <Splash />
        <HomepageMain />
        <NoProblem />
        <HowCanWeHelp />
      </main>
    </Layout>
  );
};

export default IndexPage;
